import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function Sort(props) {
  const classes = useStyles();
  const [sort, setSort] = React.useState('popular');

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = event => {
    setSort(event.target.value);
    props.setActiveSort(event.target.value);
  };

  return (<div style={{
      display: 'flex',
      justifyContent: 'center'
    }}>
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
        Sort By
      </InputLabel>
      <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={sort} onChange={handleChange} labelWidth={labelWidth}>
        <MenuItem value={'popular'}>Most Popular</MenuItem>
        <MenuItem value={'recent'}>Recently Added</MenuItem>
        <MenuItem value={'alphabetical'}>Alphabetical</MenuItem>
      </Select>
    </FormControl>
  </div>);
}
