import bramble from "../images/bramble.jpg"
import cubaLibre from "../images/cuba-libre.jpg"
import ginFizz from "../images/gin-fizz.jpg"
import mintJulep from "../images/mint-julep.jpg"
import mojito from "../images/mojito.jpg"
import negroni from "../images/negroni.jpg"
import oldFashioned from "../images/old-fashioned.jpg"
import pinaColada from "../images/pina-colada.jpg"
import whiskeySour from "../images/whiskey-sour.jpg"

export const DrinkData = [
  {
    id: 1,
    name: 'Mojito',
    description: 'The mojito is a traditional Cuban highball. It consists of five ingredients: white rum, sugar, lime juice, soda water, and mint.',
    details: "Havana, Cuba, is the birthplace of the Mojito, although the exact origin of this classic cocktail is the subject of debate. One story traces the Mojito to a similar 16th century drink known as El Draque, after Sir Francis Drake.",
    alcohols: ['Rum'],
    ingredients: [{name: 'Rum', amount: '45ml'}, {name: 'Lime Juice', amount: '30ml'}, {name: 'Soda Water', amount: 'By pref.'}, {name: 'Sugar', amount: '2 teaspoons'}, {name: 'Fresh Mint', amount: '6 sprigs'}],
    garnish: ['Sprig of Mint','Lemon Slice'],
    glass: 'Collins Glass',
    preparation: 'Muddle mint leaves with sugar and lime juice. Add a splash of soda water and fill the glass with cracked ice. Pour the rum and top with soda water. Garnish with sprig of mint leaves and lemon slice. Serve with straw.',
    image: mojito,
    imageURL: 'https://www.thespruceeats.com/thmb/sM36guFG8QZ3QlgVsRB4dLsIqvE=/960x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/mojito-5a8f339fba61770036ec61d8.jpg',
    link: '/mojito',
  },{
    id: 2,
    name: 'Negroni',
    description: 'The Negroni is a popular Italian cocktail, made of one part gin, one part vermouth rosso, and one part Campari, garnished with orange peel.',
    details: 'A properly made Negroni is stirred, not shaken, and (classically) built over ice in an old fashioned or ‘rocks’ glass and garnished with a slice of orange. Outside of Italy an orange peel is often used in place of an orange slice, either is acceptable though an orange slice is more traditional.',
    alcohols: ['Gin', 'Vermouth', 'Campari'],
    ingredients: [{name: 'Gin', amount: '30ml'}, {name: 'Vermouth', amount: '30ml'}, {name: 'Campari', amount: '30ml'}],
    garnish: 'Orange Slice',
    glass: 'Old Fashioned Glass',
    preparation: 'Stir into glass over ice, garnish and serve.',
    image: negroni,
    imageURL: 'https://upload.wikimedia.org/wikipedia/commons/c/c6/Negroni_served_in_Vancouver_BC.jpg',
    link: '/negroni',
  },
  {
    id: 3,
    name: 'Old Fashioned',
    description: 'The old fashioned is a cocktail made by muddling sugar with bitters, adding whiskey or, less commonly, brandy, and garnishing with a twist of citrus rind.',
    details: "The first mention in print of 'old fashioned cocktails' was in the Chicago Daily Tribune in February of 1880. However, the Pendennis Club, a gentlemen's club founded in 1881 in Louisville, Kentucky, claims the Old Fashioned cocktail was invented there.",
    alcohols: ['Whiskey'],
    ingredients: [{name: 'Whiskey', amount: '45ml'}, {name: 'Angostura Bitters', amount: '2 dashes'}, {name: 'Sugar Cube', amount: '1'}, {name: 'Plain Water', amount: 'A few dashes'}],
    garnish: ['Orange Twist or Cocktail Cherry'],
    glass: 'Old Fashioned Glass',
    preparation: 'Place sugar cube in old fashioned glass and saturate with bitters, add a dash of plain water. Muddle until dissolved. Fill the glass with ice cubes and add whiskey. Garnish with orange twist, and a cocktail cherry.',
    image: oldFashioned,
    link: '/old-fashioned',
  },
  {
    id: 4,
    name: 'Whiskey Sour',
    description: 'The whiskey sour is a mixed drink containing whiskey, lemon juice, sugar, and optionally, a dash of egg white. It is shaken and served either straight up or over ice.',
    details: "The oldest historical mention of a whiskey sour was published in the Wisconsin newspaper, Waukesha Plain Dealer, in 1870. In 1962, the Universidad del Cuyo published a story, citing the Peruvian newspaper El Comercio de Iquique, which indicated that Elliott Stubb created the whisky sour in Iquique in 1872.",
    alcohols: ['Whiskey'],
    ingredients: [{name: 'Whiskey', amount: '45ml'}, {name: 'Lemon Juice', amount: '30ml'}, {name: 'Simple Syrup', amount: '15ml'}, {name: 'Egg White', amount: '1'}],
    garnish: ['Maraschino Cherry and Half Orange Slice'],
    glass: 'Old Fashioned Glass',
    preparation: 'Shake with ice. Strain into chilled glass, garnish and serve.',
    image: whiskeySour,
    altImage: 'https://www.thespruceeats.com/thmb/QL__HUMVbMrbCQpJwP-2mZSFzBE=/3150x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/negroni-cocktail-recipe-759327-6-5b3f965b46e0fb00364f8d61.jpg',
    imageURL: 'https://i.pinimg.com/originals/bd/f2/fd/bdf2fd92a54e27878511cd54036ef662.jpg',
    link: '/whiskey-sour',
  },
  {
    id: 5,
    name: 'Cuba Libre',
    description: 'Rum and Coke, or the Cuba Libre, is a highball cocktail consisting of cola, rum, and in many recipes lime juice on ice.',
    details: "The cocktail originated in the early 20th century in Cuba, after the country won independence in the Spanish–American War. It subsequently became popular across Cuba, the United States, and other countries. Its simple recipe and inexpensive, ubiquitous ingredients have made it one of the world's most popular alcoholic drinks.",
    alcohols: ['Rum'],
    ingredients: [{name: 'Cola', amount: '120ml'}, {name: 'White Rum', amount: '50ml'}, {name: 'Fresh Lime Juice', amount: '10ml'}],
    garnish: ['Lime Wedge'],
    glass: 'Highball Glass',
    preparation: 'Build all ingredients in a highball glass filled with ice. Garnish with lime wedge.',
    served: 'On the rocks; poured over ice',
    image: cubaLibre,
    altImage: 'https://assets.afcdn.com/recipe/20190222/88149_w1024h768c1cx2674cy1783cxt0cyt0cxb5346cyb3561.jpg',
    imageURL: 'https://assets.afcdn.com/recipe/20190222/88149_w1024h768c1cx2674cy1783cxt0cyt0cxb5346cyb3561.jpg',
    link: '/cuba-libre',
  },
  {
    id: 6,
    name: 'Gin Fizz',
    description: 'The gin fizz is the best-known cocktail in the fizz family. It contains gin, lemon juice, and sugar.',
    details: "The drink is similar to a Tom Collins, with a possible distinction being a Tom Collins historically used 'Old Tom Gin' (a slightly sweeter precursor to London Dry Gin), whereas the kind of gin historically used in a gin fizz is unknown.",
    alcohols: ['Gin'],
    ingredients: [{name: 'Gin', amount: '45ml'}, {name: 'Fresh Lemon Juice', amount: '30ml'}, {name: 'Simple Syrup', amount: '10ml'}, {name: 'Soda Water', amount: '80ml'}],
    garnish: ['Lemon Slice'],
    glass: 'Old Fashioned Glass',
    preparation: 'Shake all ingredients with ice cubes, except soda water. Pour into glass. Top with soda water.',
    image: ginFizz,
    imageURL: 'https://thelakehousekitchen.com/wp-content/uploads/2018/08/lemon-thyme-gin-fizz-2-glasses.jpg',
    link: '/gin-fizz',
  },
  {
    id: 7,
    name: 'Pina Colada',
    description: 'The piña colada is a sweet cocktail made with rum, cream of coconut or coconut milk, and pineapple juice.',
    details: "The earliest known story states that in the 19th century, Puerto Rican pirate Roberto Cofresí, to boost his crew's morale, gave them a beverage or cocktail that contained coconut, pineapple and white rum.",
    alcohols: ['Rum'],
    ingredients: [{name: 'White Rum', amount: '30ml'}, {name: 'Pineapple Juice', amount: '90ml'}, {name: 'Cream of Coconut', amount: '30ml'}],
    garnish: ['Pineapple Slice'],
    glass: 'Poco Grande Glass',
    preparation: 'Blend all the ingredients with ice in an electric blender, pour into a large goblet or Hurricane glass and serve with straws.',
    image: pinaColada,
    imageURL: 'https://food.fnr.sndimg.com/content/dam/images/food/fullset/2011/4/25/0/CCWM_Pina-Colada-2_s3x4.jpg.rend.hgtvcom.826.620.suffix/1562077390568.jpeg',
    link: '/pina-colada',
  },
  {
    id: 8,
    name: 'Bramble',
    description: 'The bramble is a spring cocktail that brings together dry gin, lemon juice, sugar syrup, crème de mûre, and crushed ice.',
    details: "The Bramble was created in London, in 1984, by Dick Bradsell. At the time, Bradsell worked at a bar in Soho called Fred's Club, and he wanted to create a British cocktail.",
    alcohols: ['Gin'],
    ingredients: [{name: 'Gin', amount: '40ml'}, {name: 'Fresh Lemon Juice', amount: '15ml'}, {name: 'Simple Syrup', amount: '10ml'}, {name: 'Creme de Mure', amount: '15ml'}],
    garnish: ['Blackberry & Lemon Slice'],
    glass: 'Old Fashioned Glass',
    preparation: 'Fill glass with crushed ice. Build gin, lemon juice and simple syrup over. Stir, and then pour blackberry liqueur over in a circular fashion to create marbling effect. Garnish with two blackberries and lemon slice.',
    image: bramble,
    imageURL: 'https://assets.punchdrink.com/wp-content/uploads/2013/09/Bramble.jpg',
    link: '/bramble',
  },
  {
    id: 9,
    name: 'Mint Julep',
    description: 'The mint julep is a cocktail consisting primarily of bourbon, sugar, water, crushed or shaved ice, and fresh mint.',
    details: "U.S. Senator Henry Clay of Kentucky introduced the drink to Washington, D.C., at the Round Robin Bar in the famous Willard Hotel during his residence in the city.",
    alcohols: ['Whiskey'],
    ingredients: [{name: 'Bourbon Whiskey', amount: '60ml'}, {name: 'Mint Leaves', amount: '4'}, {name: 'Powdered Sugar', amount: '1 tsp'}, {name: 'Water', amount: '2 tsp'}],
    garnish: ['Mint Sprig'],
    glass: 'Highball Glass',
    preparation: 'In a highball glass gently muddle the mint, sugar and water. Fill the glass with cracked ice, add Bourbon and stir well until the glass is well frosted. Garnish with a mint sprig.',
    image: mintJulep,
    imageURL: 'https://food.fnr.sndimg.com/content/dam/images/food/fullset/2014/3/11/0/EA0917_Mint-Julep_s4x3.jpg.rend.hgtvcom.826.620.suffix/1394587606241.jpeg',
    link: '/mint-julep',
  },
];

export let activeDrinkIndex = 1;


// {
//   id: 2,
//   name: 'Pisco Sour',
//   description: 'A pisco sour is an alcoholic cocktail of Peruvian origin that is typical of the cuisines from Chile and Peru.',
//   details: "The drink's name comes from pisco, which is its base liquor, and the cocktail term sour, in reference to sour citrus juice and sweetener components. The Peruvian pisco sour uses Peruvian pisco as the base liquor and adds freshly squeezed lime juice, simple syrup, ice, egg white, and Angostura bitters. The Chilean version is similar, but uses Chilean pisco and pica lime, and excludes the bitters and egg white. Other variants of the cocktail include those created with fruits like pineapple or plants such as coca leaves.",
//   alcohols: ['Pisco'],
//   ingredients: [{name: 'Pisco', amount: '4.5cl'}, {name: 'Lime Juice', amount: '3cl'}, {name: 'Simple Syrup', amount: '2cl'}, {name: 'Egg White', amount: '1'}],
//   garnish: 'Angostura Bitters',
//   glass: 'Old Fashioned Glass',
//   preparation: 'Vigorously shake contents in a cocktail shaker with ice cubes, then strain into a glass and garnish with bitters.',
//   image: 'https://tul.imgix.net/content/article/Pisco-Sour.jpg?auto=format,compress&w=740&h=486&fit=crop',
// },
// {
//   id: 6,
//   name: 'Tom Collins',
//   description: 'The Tom Collins is a Collins cocktail made from gin, lemon juice, sugar, and carbonated water.',
//   details: "A John Collins is a cocktail which was attested to in 1869, but may be older. It is believed to have originated with a headwaiter of that name who worked at Limmer's Old House in Conduit Street in Mayfair, which was a popular London hotel and coffee house around 1790–1817.",
//   alcohols: ['Gin'],
//   ingredients: [{name: 'Gin', amount: '4.5cl'}, {name: 'Lemon Juice', amount: '3cl'}, {name: 'Sugar Syrup', amount: '1.5cl'}, {name: 'Carbonated Water', amount: '6cl'}],
//   garnish: ['Lemon Slice and Maraschino Cherry'],
//   glass: 'Collins Glass',
//   preparation: 'Pour all ingredients directly into highball glass filled with ice. Stir gently. Garnish. Add a dash of Angostura bitters.',
//   // image: 'https://i.pinimg.com/originals/bd/f2/fd/bdf2fd92a54e27878511cd54036ef662.jpg',
// },
