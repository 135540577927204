import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function Filter(props) {
  const classes = useStyles();
  const [filter, setFilter] = React.useState(props.passedFilter ? props.passedFilter : 'all');

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = event => {
    setFilter(event.target.value);
  };

  React.useEffect(() => {
    props.setActiveFilter(filter);
  });

  // if (props.passedFilter) {
  //   props.setActiveFilter(props.passedFilter)
  // }

  return (<div style={{
      display: 'flex',
      justifyContent: 'center'
    }}>
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
        Filter
      </InputLabel>
      <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value={filter} onChange={handleChange} labelWidth={labelWidth}>
        <MenuItem value={'all'}>All Liquors</MenuItem>
        <MenuItem value={'gin'}>Gin</MenuItem>
        <MenuItem value={'rum'}>Rum</MenuItem>
        <MenuItem value={'whiskey'}>Whiskey</MenuItem>
      </Select>
    </FormControl>
  </div>)
}

// <MenuItem value={'tequila'}>Tequila</MenuItem>
// <MenuItem value={'vodka'}>Vodka</MenuItem>
