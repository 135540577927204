import React, {useState, useEffect} from 'react';

import DrinkCard from '../components/DrinkCard'
import {DrinkData} from '../data/DrinkData.js'
import MenuAppBar from '../components/AppBar'
import Filter from '../components/Filter'
import Sort from '../components/Sort'
// import Grow from '@material-ui/core/Grow';
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import {navigate} from "gatsby"
import Grid from '@material-ui/core/Grid';

import getFirebase from '../utils/firebase';

import CircularProgress from '@material-ui/core/CircularProgress';

import {makeStyles} from '@material-ui/core/styles';

import Layout from "../components/layout";

import Typography from '@material-ui/core/Typography';

import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SEO from "../components/seo"

const useStyles = makeStyles(theme => ({
  cardContainer: {
    margin: '30px 0px',
    [
      theme
        .breakpoints
        .up('sm')
    ]: {
      // height: '550px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '0px 0px 50px'
    }
  }
}));

export default function Catalog({location}) {
  const classes = useStyles();
  const firebase = getFirebase();

  // State
  // const [activeUser, setActiveUser] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeSort, setActiveSort] = React.useState('popular');
  const [activeFilter, setActiveFilter] = React.useState('all');
  const [userFavourites, setUserFavourites] = React.useState(null);
  const [drinks, setDrinks] = React.useState(null);

  // let passedFilter

  // if (location.state.filter && activeFilter === 'all') {
  //   setActiveFilter(location.state.filter)
  // }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!firebase)
      return;
    if (isLoaded)
  return;
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          // alert('User is signed in')
          // setIsSignedIn(!!user);
          // console.log('test')
          const db = firebase.firestore();
          let docRef = db
            .collection("users")
            .doc(user.uid);
          docRef
            .get()
            .then(function(doc) {
              if (doc.exists) {
                setUserFavourites(doc.data()['favourites'])
                // setDrinks(doc.data()['drinks'])
                // console.log(drinks)
                // setIsLoaded(true);
              } else {
                // doc.data() will be undefined in this case
                navigate('/signedin')
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error);
            });
          let drinksRef = db
            .collection("drinks").doc('classics')
          drinksRef
            .get()
            .then(function(doc) {
              if (doc.exists) {
                setDrinks(doc.data())
                 // console.log(drinks)
                 // console.log(drinks['1'].likedBy.length)
                setIsLoaded(true);
              } else {
                navigate('/home')
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error);
            });
        } else {
          navigate('/')
        }
      });
  });

  const handleRandomClick = () => {
    navigate("/random");
  }

  let drinkALikes
  let drinkBLikes

  function sortArrayByPopular(array) {
    return array.sort(function(a, b) {
      var idA = a.id
      var idB = b.id
      drinkALikes = (drinks ? drinks[idA].likedBy.length : null)
      drinkBLikes = (drinks ? drinks[idB].likedBy.length : null)
      if (drinkALikes < drinkBLikes) {
        return 1;
      }
      if (drinkALikes > drinkBLikes) {
        return -1;
      }

      // names must be equal
      return 0;
    });
  };

  function sortArrayByNameDesc(array) {
    return array.sort(function(a, b) {
      var nameA = a
        .name
        .toUpperCase(); // ignore upper and lowercase
      var nameB = b
        .name
        .toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  function sortArrayByRecent(array) {
    return array.sort(function(a, b) {
      var idA = a.id
      var idB = b.id
      if (idA > idB) {
        return -1;
      }
      if (idA < idB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  let cardArray;

  if (activeFilter === 'all') {
    cardArray = DrinkData
  } else if (activeFilter === 'gin') {
    cardArray = DrinkData.filter(drink => drink.alcohols.includes('Gin'))
  } else if (activeFilter === 'rum') {
    cardArray = DrinkData.filter(drink => drink.alcohols.includes('Rum'))
  } else if (activeFilter === 'tequila') {
    cardArray = DrinkData.filter(drink => drink.alcohols.includes('Tequila'))
  } else if (activeFilter === 'vodka') {
    cardArray = DrinkData.filter(drink => drink.alcohols.includes('Vodka'))
  } else if (activeFilter === 'whiskey') {
    cardArray = DrinkData.filter(drink => drink.alcohols.includes('Whiskey'))
  }

  let sortedCardArray;

  if (activeSort === 'popular') {
    sortedCardArray = sortArrayByPopular(cardArray)
  } else if (activeSort === 'recent') {
    sortedCardArray = sortArrayByRecent(cardArray)
  } else if (activeSort === 'alphabetical') {
    sortedCardArray = sortArrayByNameDesc(cardArray)
  }

  // console.log()
  //
  // userFavourites.includes(drinkObject.id)

  const cardCatalog = sortedCardArray.map((drinkObject) => <Grid item="item" xs={12} md={6} lg={4} style={{
      backgroundColor: '',
    }}>
    <Grid container="container" justify="center" alignItems="center" className={classes.cardContainer}>
      <DrinkCard isFavourite={userFavourites
          ? userFavourites.includes(drinkObject.id)
          : false
} likes={drinks
          ? drinks[drinkObject.id].likedBy.length
          : 0} drinkData={drinkObject}/>
    </Grid>
  </Grid>)

  let finalPage = isLoaded
    ? <Layout>
    <SEO title='Classics'/>
        <div>
          <MenuAppBar handleRandomClick={handleRandomClick}/>
            <div style={{
                padding: '8vh 2vw 4vh'
              }}>
              <Grid container="container" direction={matches
                  ? "column"
                  : "row"} justify={matches
                  ? "center"
                  : "space-between"} alignItems="center" style={{
                  padding: '20px 20px',
                  backgroundColor: ''
                }}>
                <Grid item="item">
                  <Typography style={{
                      fontFamily: 'Work Sans',
                      fontWeight: '800',
                      fontSize: matches
                        ? '42px'
                        : '60px',
                      backgroundColor: '',
                      lineHeight: '1',
                      textAlign: 'center'
                    }}>Classic Drinks</Typography>
                </Grid>
                <Grid item="item">
                  <Grid container="container" direction="row" justify="center" alignItems="center" style={{
                      paddingBottom: matches
                        ? "15px"
                        : "0px",
                      paddingTop: matches
                        ? "15px"
                        : "0px"
                    }}>
                    <Sort setActiveSort={setActiveSort}/>
                    <Filter setActiveFilter={setActiveFilter} passedFilter={location.state
                        ? location.state.filter
                        : 'all'}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container="container" direction='row' justify="space-evenly" alignItems="stretch">
                {cardCatalog}
              </Grid>
            </div>
        </div>
      </Layout>
    : <div><MenuAppBar/>
      <Grid container="container" direction="row" justify="center" alignItems="center" style={{
          height: '100vh',
          width: '100vw'
        }}><CircularProgress/></Grid>
    </div>

  // Render
  return (finalPage)
}
