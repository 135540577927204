import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Img from "gatsby-image";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import 'typeface-work-sans';
import Grow from '@material-ui/core/Grow';

import Grid from '@material-ui/core/Grid';

import Chip from '@material-ui/core/Chip';

import {navigate} from "gatsby"

// import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles(theme => ({
  card: {
    width: '350px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw'
    }
  },
  media: {
    height: 250
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 2,
    paddingBottom: 2
  },
  content: {
    padding: '10px 15px 0px',
    display: 'flex'
  },
  headingTypography: {
    paddingBottom: 5,
    paddingTop: 3,
    fontFamily: 'Work Sans',
    fontWeight: 'bold',
    fontSize: 30
  },
  bodyTypography: {
    paddingBottom: 10,
    paddingTop: 5,
    fontSize: 16
  },
  stretch: {
    display: 'flex',
    alignContent: 'stretch',
    alignItems: 'stretch'
  },
  chip: {
    margin: '5px'
  }
}));

// <Grid container="container" direction="row" justify="flex-end" alignItems="center" style={{
//     marginTop: '20px',
//     marginRight: '30px',
//      margin: '20px 0px 5px',
//     position: 'absolute'
//   }}>
//   <FavoriteIcon color='secondary' style={{
//       position: 'absolute'
//     }}/>
// </Grid>

export default function ExampleCard(props) {

  const classes = useStyles();

  const handleClick = () => {
    navigate("/recipe", {
      state: {
        drinkData: props.drinkData
      }
    })
    // navigate(props.drinkData.link)
  }

  return (<Grow in="in">
    <Card onClick={handleClick} style={{
        width: props.cardWidth
      }} className={classes.card} raised={props.raised}>
      <div className={classes.stretch}>
        <CardActionArea>
          <CardMedia className={classes.media} image={props.drinkData.image} title={'The ' + props.drinkData.name}/>
          <div className={classes.content}>
            <CardContent style={{
                backgroundColor: '',
                width: '100%',
                height: '100%'
              }}>
              <Grid>
                <Typography className={classes.headingTypography} align='center' utterBottom="utterBottom" variant="h5" component="h2">
                  {'The ' + props.drinkData.name}
                </Typography>
                <Grid container="container" direction="row" justify="center" alignItems="center" style={{
                    margin: '-5px 0px 5px'
                  }}>
                  {
                    !props.homepage && <Chip className={classes.chip} label={props
                          .drinkData
                          .alcohols[0]} variant="outlined" color='primary' clickable='true'/>
                  }
                  {
                    !props.homepage && <Chip className={classes.chip} label={props.likes + (
                          props.likes === 1
                          ? ' Like'
                          : ' Likes')} variant="outlined" color='secondary' clickable='true'/>
                  }
                  {props.isFavourite === true && <Chip className={classes.chip} label='Saved' variant="outlined" color='' clickable='true'/>}
                </Grid>
                <div className={classes.actions}>
                  <CardActions>
                    <Button size="medium" variant='contained' color="primary" style={{
                        margin: '0px 0px 5px'
                      }}>
                      View Recipe
                    </Button>
                  </CardActions>
                </div>
              </Grid>
            </CardContent>
          </div>
        </CardActionArea>
      </div>
    </Card>
  </Grow>);
}

// <Typography className={classes.bodyTypography} align='center' variant="body2" color="textSecondary" component="p">
//   {props.drinkData.description}
// </Typography>
